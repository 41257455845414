import React, { useState, useRef, useEffect } from "react";
import axios from "axios";

export default function UploadExcelCSV({ onContinueClick }) {
  const [file, setFile] = useState(null);
  const [error, setError] = useState("");
  const [progress, setProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [uploaded, setUploaded] = useState(null);
  const [hasSource, setHasSource] = useState(false);

  const fileInputRef = useRef(null);

  const allowedExtensions = [".csv", ".xlsx", ".xls", ".json"];
  const base_url = "https://saas.ravian.ai/v1/api";
  const apiCreateDataSourceURL = `${base_url}/source/create`;
  const apiRemoveDataSourceURL = `${base_url}/source/remove`;
  const apiSignedURL = `${base_url}/source/generate-presigned-url`;
  const apiNewThread = `${base_url}/thread/new`;
  const authkey = localStorage.getItem("token");

  useEffect(() => {
    setHasSource(uploaded !== null);
  }, [uploaded]);

  const isAdvancedUpload = () => {
    const div = document.createElement("div");
    return (
      ("draggable" in div ||
        ("ondragstart" in div && "ondrop" in div)) &&
      "FormData" in window &&
      "FileReader" in window
    );
  };

  const validateFileType = (file) => {
    return allowedExtensions.some((ext) => file.name.toLowerCase().endsWith(ext));
  };

  const handleContinueClick = async () => {
    try {
      if (!uploaded) {
        setError("Please upload a file first");
        return;
      }
  
      const payload = {
        aiName: "Newton",
        title: "New Chat",
        sources: [uploaded._id],
      };
  
      const options = {
        headers: { authkey: authkey },
      };
  
      const response = await axios.post(apiNewThread, payload, options);
  
      if (response.data.meta.status) {
        onContinueClick({
          ...response.data,
          chat_id: response.data.data._id,
          file_path: uploaded.fileURL, 
        });
      } else {
        setError(response.data.meta.msg);
      }
    } catch (err) {
      console.error("Error creating new thread:", err);
      setError(
        err.response?.data?.message ||
          "An error occurred while creating the thread. Please try again."
      );
    }
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile && validateFileType(selectedFile)) {
      setFile(selectedFile);
      setError("");
    } else {
      setError(`File type not allowed: ${selectedFile.name}`);
      setFile(null);
    }
  };

  const generateSignedURL = async (fileName, contentType) => {
    if (!authkey) {
      setError("Authentication token not found. Please login again.");
      return;
    }

    try {
      const options = {
        params: {
          fileName,
          contentType
        },
        headers: { authkey: authkey }
      };
      const { data: response } = await axios.get(apiSignedURL, options);

      if (response.meta.status) {
        return response.data;
      } else {
        setError(response.meta.msg);
      }
    } catch (err) {
      console.log(err);
      setError(err.response?.data?.message || "An error occurred. Please try again.");
    }
  };

  const uploadUsingSignedURL = async (url, file, index = null) => {
    try {
      const options = {
        headers: {
          'Content-Type': file.type,
        },
        onUploadProgress: (progressEvent) => {
          const progress = Math.round((progressEvent.loaded * 94) / progressEvent.total);
          setProgress(progress);
        },
      };
      const response = await axios.put(url, file, options);

      if (response.status === 200) {
        return response;
      } else {
        setError(response.meta.msg);
      }
    } catch (err) {
      console.log(err);
      setError(err.response?.data?.message || "An error occurred. Please try again.");
    }
  };

  const handleUploadClick = async () => {
    if (!file) {
      setError("Please select a valid file first");
      return;
    }

    if (!isUploading) {
      setIsUploading(true);
      setError("");
      try {
        const { url, key } = await generateSignedURL(file.name, file.type);
        const res = await uploadUsingSignedURL(url, file);

        if (res.status === 200) {
          const fileDetails = {
            fileName: file.name,
            fileType: file.type,
            fileSize: file.size,
            fileURL: url.split('?')[0],
            fileKey: key,
          };
          const data = await createDataSource(fileDetails);
          setUploaded({ ...data });
          setIsUploading(false);
          console.log("File uploaded successfully!");
          setHasSource(true);
        }
      } catch (error) {
        setError(error.message);
        console.error("Error uploading file", error);
        setIsUploading(false);
      }
    }
  };

  const createDataSource = async (fileDetails) => {
    try {
      const options = { headers: { authkey: authkey } };
      const { data: response } = await axios.post(apiCreateDataSourceURL, fileDetails, options);

      if (response.meta.status) {
        return response.data;
      } else {
        setError(response.meta.msg);
        return null;
      }
    } catch (err) {
      console.log(err);
      setError(err.response?.data?.message || "An error occurred. Please try again.");
      return null;
    }
  };

  const removeDataSource = async (dataSourceId) => {
    try {
      const options = { headers: { authkey: authkey } };
      const { data: response } = await axios.delete(`${apiRemoveDataSourceURL}/${dataSourceId}`, options);

      if (response.meta.status) {
        return response.data;
      } else {
        setError(response.meta.msg);
      }
    } catch (err) {
      console.log(err);
      setError(err.response?.data?.message || "An error occurred. Please try again.");
    }
  };

  const handleRemoveFile = async () => {
    if (uploaded?._id) {
      await removeDataSource(uploaded._id);
    }

    setFile(null);
    setProgress(0);
    setUploaded(null);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const droppedFile = e.dataTransfer.files[0];
    if (droppedFile && validateFileType(droppedFile)) {
      setFile(droppedFile);
      setError("");
    } else {
      setError(`File type not allowed: ${droppedFile.name}`);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleBrowseClick = () => {
    fileInputRef.current.value = null;
    fileInputRef.current.click();
  };

  return (
    <div className="upload-files-container">
      <div
        className="drag-file-area"
        onDrop={isAdvancedUpload() ? handleDrop : null}
        onDragOver={isAdvancedUpload() ? handleDragOver : null}
        onClick={handleBrowseClick}
      >
        <i className="fa fa-upload upload-icon"></i>
        <h3 className="dynamic-message">
          {file ? "File Dropped Successfully!" : "Drag & drop file here"}
        </h3>
        <input
          type="file"
          className="default-file-input"
          ref={fileInputRef}
          onChange={handleFileChange}
          style={{ display: "none" }}
          accept=".csv, .xlsx, .xls, .json" // Updated accept attribute
        />
        <span className="text-white">or browse file from device</span>
      </div>
      {error && (
        <span className="cannot-upload-message">
          <span className="material-icons-outlined">error</span> {error}{" "}
          <span
            className="material-icons-outlined cancel-alert-button"
            onClick={() => setError("")}
          >
            cancel
          </span>
        </span>
      )}
      {file && (
        <div className="filebox">
          <div className="file-block">
            <div className="file-info">
              <span className="material-icons-outlined file-icon">description</span>
              <span className="file-name">{file.name}</span> |{" "}
              <span className="file-size">{(file.size / 1024).toFixed(1)} KB</span>
            </div>
            <span className="material-icons remove-file-icon" onClick={handleRemoveFile}>
              delete
            </span>
            <div className="progress-bar" style={{ width: `${progress}%` }}></div>
          </div>
        </div>
      )}
      <button type="button" className="upload-button" onClick={handleUploadClick}>
        {isUploading ? (
          <i className="fa fa-check-circle-o upload-button-icon"></i>
        ) : (
          "Upload"
        )}
      </button>
      <div className="btn-box d-flex justify-content-between">
        <button className="buttons" onClick={handleContinueClick} disabled={!hasSource}>
          Continue
        </button>
      </div>
    </div>
  );
}
