import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import MainChatWindow from '../components/ChatBox/MainChatWindow';
import Tabs from '../components/NavBar/Tabs';
import SideBar from '../components/SideBar/SideBar';
import { ChatProvider } from './ChatBox/ChatContext';
import axios from 'axios';

export default function Home() {
  const [isNewChat, setIsNewChat] = useState(false);
  const [isSidebarVisible, setIsSidebarVisible] = useState(true);
  const [selectedThread, setSelectedThread] = useState(null);
  const [sidebarThreads, setSidebarThreads] = useState([]);
  const { threadId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (threadId) {
      fetchChatHistory(threadId);
    }
  }, [threadId]);

  const fetchChatHistory = async (id) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`https://saas.ravian.ai/v1/api/chat/list?threadId=${id}`, {
        headers: {
          'authkey': token
        }
      });

      if (response.data.meta.status) {
        const chatHistory = response.data.data;
        setSelectedThread({
          threadId: id,
          chatHistory: chatHistory,
          filePath: chatHistory[0]?.file_path,
          chatId: chatHistory[0]?.chat_id
        });
      }
    } catch (error) {
      console.error('Error fetching chat history:', error);
    }
  };

  const handleNewChat = () => {
    setIsNewChat(true);
    setSelectedThread(null);
    navigate('/newton');
  };

  const handleSelectChat = (thread, chatHistory) => {
    setSelectedThread({
      threadId: thread._id,
      chatHistory: chatHistory,
      filePath: thread.file_path,
      chatId: thread.chat_id
    });
    setIsNewChat(false);
    navigate(`/newton/${thread._id}`);
  };

  const updateSidebarThreads = (newThread) => {
    setSidebarThreads(prevThreads => [newThread, ...prevThreads]);
    navigate(`/newton/${newThread._id}`);
  };

  const handleToggleSidebar = () => {
    setIsSidebarVisible(prev => !prev);
  };

  const handleCloseSidebar = () => {
    setIsSidebarVisible(false);
  };

  return (
    <ChatProvider>
      <div className="app-container">
        <div className='bigbox'>
          <Tabs handleToggleSidebar={handleToggleSidebar} />
          <div className='smallbox'>
            <SideBar
              onNewChat={handleNewChat}
              onSelectChat={handleSelectChat}
              isSidebarVisible={isSidebarVisible}
              onToggleSidebar={handleToggleSidebar}
              chatThreads={sidebarThreads}
              setChatThreads={setSidebarThreads}
            />
            <MainChatWindow
              isNewChat={isNewChat}
              setIsNewChat={setIsNewChat}
              selectedThread={selectedThread}
              isSidebarVisible={isSidebarVisible}
              handleCloseSidebar={handleCloseSidebar}
              updateSidebarThreads={updateSidebarThreads}
            />
          </div>
        </div>
      </div>
    </ChatProvider>
  );
}