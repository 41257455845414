import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { GoogleLogin } from '@react-oauth/google';
import GithubLoginButton from "react-github-login-button";
import { jwtDecode } from "jwt-decode";

export default function Register() {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError("");
        console.log('Name:', name);

        try {
            const response = await axios.post("https://saas.ravian.ai/v1/api/user/signup", {
                name,
                email,
                password
            });

            console.log('Registration response:', response.data);

            if (response.data && response.data.token) {
                localStorage.setItem('token', response.data.token);
                localStorage.setItem('username', name);
                navigate('/otpverification', { state: { email: email } });
            } else {
                setError('Registration failed. Please try again.');
            }
        } catch (err) {
            if (err.response?.status === 409) {
                setError("An account with this email already exists. Please try logging in or use a different email.");
            } else {
                setError(err.response?.data?.message || "An error occurred. Please try again.");
            }
        }
    };

    const handleGoogleSuccess = async (response) => {
        const userInfo = jwtDecode(response.credential);
        console.log(userInfo);

        try {
            const apiResponse = await axios.post("https://saas.ravian.ai/v1/api/user/social-login", {
                email: userInfo.email,
                name: userInfo.name,
                socialLoginType: "GMAIL",
            });

            if (apiResponse.data && apiResponse.data.token) {
                localStorage.setItem('token', apiResponse.data.token);
                localStorage.setItem('username', userInfo.name);
                console.log('Username stored:', userInfo.name);
                navigate("/");
            } else {
                setError("Google authentication failed. Please try again.");
            }
        } catch (err) {
            setError(err.response?.data?.message || "An error occurred. Please try again.");
        }
    };

    const handleGoogleFailure = () => {
        console.log('google error')
        setError("Google Sign-In failed. Please try again.");
    };

    const handleGitHubLogin = async () => {
        const githubClientId = "Ov23liwYW4WJz3A2p6sS";
        const redirectUri = encodeURIComponent("http://localhost:3000/");
        const githubUrl = `https://github.com/login/oauth/authorize?client_id=${githubClientId}&redirect_uri=${redirectUri}`;

        window.open(githubUrl, "_blank", "width=600,height=600");
    };

    const handleLoginSuccess = async () => {
        const githubClientId = "Ov23liwYW4WJz3A2p6sS";
        const redirectUri = encodeURIComponent("http://localhost:3000/");
        const githubUrl = `https://github.com/login/oauth/authorize?client_id=${githubClientId}&redirect_uri=${redirectUri}`;

        window.open(githubUrl, "_blank", "width=600,height=600");
    };

    return (
        <div className="login-form d-flex justify-content-center align-items-center">
            <div className="wrapper">
                <div className="form">
                    <div className="screen-effect"></div>
                    <div className="glass-effect"></div>
                    <div className="form-bigbox">
                        <div className="formblock">
                            <div className="form-inner-block">
                                <div className="formbox">
                                    <h1>Register</h1>
                                    {error && <div className="alert alert-danger">{error}</div>}
                                    <form onSubmit={handleSubmit}>
                                        <div className="mb-3">
                                            <label className="form-label">User Name</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Enter Your User Name"
                                                value={name}
                                                onChange={(e) => setName(e.target.value)}
                                                required
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label">Email Address</label>
                                            <input
                                                type="email"
                                                className="form-control"
                                                placeholder="Enter Your Email Address"
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                                required
                                            />
                                        </div>
                                        <div className="mb-4">
                                            <label className="form-label">Password</label>
                                            <input
                                                type="password"
                                                className="form-control"
                                                placeholder="Enter Your Password"
                                                value={password}
                                                onChange={(e) => setPassword(e.target.value)}
                                                required
                                            />
                                        </div>
                                        <button type="submit" className="btn d-block">Register Now</button>
                                        <div className="other-opt">Already a member <Link to="/login"><b>Login Now</b></Link></div>
                                        <div className="other-opt">or continue with</div>
                                        <ul>
                                            <li>
                                                <GoogleLogin
                                                    onSuccess={handleGoogleSuccess}
                                                    onError={handleGoogleFailure}
                                                    scope="profile email"
                                                    useOneTap
                                                />
                                            </li>
                                            {/* <li>
                                <GithubLoginButton onClick={handleGitHubLogin} />
                            </li> */}
                                        </ul>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}